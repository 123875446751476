import React from 'react';

import { Spin } from 'antd';

const CircularProgress = () => (
  <div className="loader loading">
    <Spin size="large" />
  </div>
);
export default CircularProgress;
